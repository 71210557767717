/**
 * Table Of Content
 *
 *  1. Globals
 *  2. Headers
 *  3. Navigations
 *  4. Banners
 *  5. Footers
 *  6. Posts
 *  7. Widgets
 *  8. Custom Templates
 */

/******************* 
Global Styles 
*******************/

* {
    outline: none;
}

html {
    position: relative;
    min-height: 100%;
    background: $white;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 1024px) {
    html {
        min-height: 100%;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }
}

body {
    font-family: $bodyfont;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    color: $bodytext;
    font-weight: 400;
    -webkit-overflow-scrolling: touch;

    //Patriot pals
    position: relative;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-attachment: fixed !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 1024px) {
    body {
        -webkit-background-size: cover !important;
        -moz-background-size: cover !important;
        -o-background-size: cover !important;
        background-size: cover !important;
        background-attachment: scroll !important;
        background-repeat: no-repeat !important;
        background-position: center center !important;
    }
}

a:hover,
a:focus {
    text-decoration: none;
}

a {
    color: $info-light !important;
    &:hover,
    &:focus {
        color: $inverse-dark;
    }
}

a.link {
    color: $info-light;
    &:hover,
    &:focus {
        color: $info-light;
    }
}

.link {
    color: $info-light;
    &:hover,
    &:focus {
        color: $info-light;
    }
}

.img-responsive {
    width: 100%;
    height: auto;
    display: inline-block;
}

.img-rounded {
    border-radius: $radius;
}

html body .mdi:before,
html body .mdi-set {
    line-height: initial;
}

/*******************
Headings
*******************/

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $info-light;
    font-family: $headingfont;
    font-weight: 500;
}

h1 {
    line-height: 40px;
    font-size: 36px;
}

h2 {
    line-height: 36px;
    font-size: 24px;
}

h3 {
    line-height: 30px;
    font-size: 21px;
}

h4 {
    line-height: 22px;
    font-size: 18px;
}

h5 {
    line-height: 18px;
    font-size: 14px;
    font-weight: 500;
}

h6 {
    line-height: 16px;
    font-size: 14px;
    font-weight: 400;
}

.display-5 {
    font-size: 3rem !important;
}

.display-6 {
    font-size: 36px;
}

.box {
    border-radius: $radius;
    padding: 10px;
}

.no-wrap {
    td,
    th {
        white-space: nowrap;
    }
}

/*******************
Opacity
*******************/

.op-5 {
    opacity: 0.5;
}

.op-3 {
    opacity: 0.3;
}

/*******************
Blockquote
*******************/

html body blockquote {
    border-left: 5px solid $themecolor;
    border: 1px solid $border;
    padding: 15px;
}

.clear {
    clear: both;
}

ol li {
    margin: 5px 0;
}

/*******************
Paddings
*******************/

html body {
    .dl {
        display: inline-block;
    }
    .db {
        display: block;
    }
    .p-0 {
        padding: 0px;
    }
    .p-10 {
        padding: 10px;
    }
    .p-20 {
        padding: 20px;
    }
    .p-30 {
        padding: 30px;
    }
    .p-l-0 {
        padding-left: 0px;
    }
    .p-l-10 {
        padding-left: 10px;
    }
    .p-l-20 {
        padding-left: 20px;
    }
    .p-r-0 {
        padding-right: 0px;
    }
    .p-r-10 {
        padding-right: 10px;
    }
    .p-r-20 {
        padding-right: 20px;
    }
    .p-r-30 {
        padding-right: 30px;
    }
    .p-r-40 {
        padding-right: 40px;
    }
    .p-t-0 {
        padding-top: 0px;
    }
    .p-t-10 {
        padding-top: 10px;
    }
    .p-t-20 {
        padding-top: 20px;
    }
    .p-t-30 {
        padding-top: 30px;
    }
    .p-b-0 {
        padding-bottom: 0px;
    }
    .p-b-5 {
        padding-bottom: 5px;
    }
    .p-b-10 {
        padding-bottom: 10px;
    }
    .p-b-20 {
        padding-bottom: 20px;
    }
    .p-b-30 {
        padding-bottom: 30px;
    }
    .p-b-40 {
        padding-bottom: 40px;
    }

    .m-0 {
        margin: 0px;
    }
    .m-l-5 {
        margin-left: 5px;
    }
    .m-l-10 {
        margin-left: 10px;
    }
    .m-l-15 {
        margin-left: 15px;
    }
    .m-l-20 {
        margin-left: 20px;
    }
    .m-l-30 {
        margin-left: 30px;
    }
    .m-l-40 {
        margin-left: 40px;
    }
    .m-r-5 {
        margin-right: 5px;
    }
    .m-r-10 {
        margin-right: 10px;
    }
    .m-r-15 {
        margin-right: 15px;
    }
    .m-r-20 {
        margin-right: 20px;
    }
    .m-r-30 {
        margin-right: 30px;
    }
    .m-r-40 {
        margin-right: 40px;
    }
    .m-t-0 {
        margin-top: 0px;
    }
    .m-t-5 {
        margin-top: 5px;
    }
    .m-t-10 {
        margin-top: 10px;
    }
    .m-t-15 {
        margin-top: 15px;
    }
    .m-t-20 {
        margin-top: 20px;
    }
    .m-t-30 {
        margin-top: 30px;
    }
    .m-t-40 {
        margin-top: 40px;
    }
    .m-b-0 {
        margin-bottom: 0px;
    }
    .m-b-5 {
        margin-bottom: 5px;
    }
    .m-b-10 {
        margin-bottom: 10px;
    }
    .m-b-15 {
        margin-bottom: 15px;
    }
    .m-b-20 {
        margin-bottom: 20px;
    }
    .m-b-30 {
        margin-bottom: 30px;
    }
    .m-b-40 {
        margin-bottom: 40px;
    }

    .vt {
        vertical-align: top;
    }
    .vm {
        vertical-align: middle;
    }
    .vb {
        vertical-align: bottom;
    }

    .font-bold {
        font-weight: 700;
    }
    .font-normal {
        font-weight: normal;
    }
    .font-light {
        font-weight: 300;
    }
    .font-medium {
        font-weight: 500;
    }
    .font-16 {
        font-size: 16px;
    }
    .font-14 {
        font-size: 14px;
    }
    .font-10 {
        font-size: 10px;
    }
    .font-18 {
        font-size: 18px;
    }
    .font-20 {
        font-size: 20px;
    }
    .b-0 {
        border: none;
    }
    .b-r {
        border-right: 1px solid $border;
    }
    .b-l {
        border-left: 1px solid $border;
    }
    .b-b {
        border-bottom: 1px solid $border;
    }
    .b-t {
        border-top: 1px solid $border;
    }
    .b-all {
        border: 1px solid $border !important;
    }
    .text-blue {
        color: $blue;
    }
    .text-purple {
        color: $purple;
    }
    .text-primary {
        color: $primary;
    }
    .text-megna {
        color: $megna;
    }
    .text-dark {
        color: $bodytext;
    }
    .text-themecolor {
        color: $themecolor;
    }

    .bg-megna {
        background-color: $megna;
    }
    .bg-theme {
        background-color: $themecolor;
    }
    .bg-inverse {
        background-color: $inverse;
    }
    .bg-purple {
        background-color: $purple;
    }
    .bg-light-part {
        background-color: $bglight;
    }
    .bg-light-primary {
        background-color: $light-primary;
    }
    .bg-light-success {
        background-color: $light-success;
    }
    .bg-light-info {
        background-color: $light-info;
    }
    .bg-light-extra {
        background-color: $extra-light;
    }
    .bg-light-warning {
        background-color: $light-warning;
    }
    .bg-light-danger {
        background-color: $light-danger;
    }
    .bg-light-inverse {
        background-color: $light-inverse;
    }
    .bg-light {
        background-color: $light;
    }
    .bg-white {
        background-color: $white;
    }
}

/*******************
Margin
*******************/

/*******************
vertical alignment
*******************/

/*******************
font weight
*******************/

/*******************
Border
*******************/

/*******************
Thumb size
*******************/

.thumb-sm {
    height: 32px;
    width: 32px;
}

.thumb-md {
    height: 48px;
    width: 48px;
}

.thumb-lg {
    height: 88px;
    width: 88px;
}

.hide {
    display: none;
}

.img-circle {
    border-radius: 0.25rem;
}

.radius {
    border-radius: $radius;
}

/*******************
Text Colors
*******************/

.text-white {
    color: $white !important;
}

.text-danger {
    color: $danger !important;
}

.text-muted {
    color: $muted !important;
}

.text-warning {
    color: $warning !important;
}

.text-success {
    color: $success !important;
}

.text-info {
    color: $info-light !important;
}

.text-inverse {
    color: $inverse !important;
}

/*******************
Background Colors
*******************/

.bg-primary {
    background-color: $primary !important;
}

.bg-success {
    background-color: $success !important;
}

.bg-info {
    background-color: $info !important;
}

.bg-warning {
    background-color: $warning !important;
}

.bg-danger {
    background-color: $danger !important;
}

/*******************
Rounds
*******************/

.round {
    line-height: 28px;
    color: $white;
    width: 30px;
    height: 30px;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    border-radius: 100%;
    background: $info;
    img {
        border-radius: 100%;
    }
}

.round-lg {
    line-height: 65px;
    width: 60px;
    height: 60px;
    font-size: 30px;
}

.round.round-info {
    background: $info;
}

.round.round-warning {
    background: $warning;
}

.round.round-danger {
    background: $danger;
}

.round.round-success {
    background: $success;
}

.round.round-primary {
    background: $primary;
}

/*******************
Labels
*******************/

.label {
    padding: 3px 10px;
    line-height: 13px;
    color: $white;
    font-weight: 400;
    border-radius: $radius;
    font-size: 75%;
}

.label-rounded {
    border-radius: 60px;
}

.label-custom {
    background-color: $megna;
}

.label-success {
    background-color: $success;
}

.label-info {
    background-color: #0d996b;
    color: #fff !important;
}

.label-warning {
    background-color: $warning;
}

.label-danger {
    background-color: $danger;
}

.label-megna {
    background-color: $megna;
}

.label-primary {
    background-color: $primary;
}

.label-purple {
    background-color: $purple;
}

.label-red {
    background-color: $red;
}

.label-inverse {
    background-color: $inverse;
}

.label-default {
    background-color: $light;
}

.label-white {
    background-color: $white;
}

.label-light-success {
    background-color: $light-success;
    color: $success;
}

.label-light-info {
    background-color: $light-info;
    color: $info;
}

.label-light-warning {
    background-color: $light-warning;
    color: $warning;
}

.label-light-danger {
    background-color: $light-danger;
    color: $danger;
}

.label-light-megna {
    background-color: $light-megna;
    color: $megna;
}

.label-light-primary {
    background-color: $light-primary;
    color: $primary;
}

.label-light-inverse {
    background-color: $light-inverse;
    color: $inverse;
}

/*******************
Pagination
*******************/

.pagination > li:first-child > a,
.pagination > li:first-child > span {
    border-bottom-left-radius: $radius;
    border-top-left-radius: $radius;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
    border-bottom-right-radius: $radius;
    border-top-right-radius: $radius;
}

.pagination > li > a,
.pagination > li > span {
    color: $dark;
}

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
    background-color: $light;
}

.pagination-split li {
    margin-left: 5px;
    display: inline-block;
    float: $lft;
}

.pagination-split li:first-child {
    margin-left: 0;
}

.pagination-split li a {
    -moz-border-radius: $radius;
    -webkit-border-radius: $radius;
    border-radius: $radius;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
    background-color: $themecolor;
    border-color: $themecolor;
}

.pager li > a,
.pager li > span {
    -moz-border-radius: $radius;
    -webkit-border-radius: $radius;
    border-radius: $radius;
    color: $dark;
}

/*******************
Table Cell
*******************/

.table-box {
    display: table;
    width: 100%;
}

.table.no-border {
    tbody {
        td {
            border: 0px;
        }
    }
}

.cell {
    display: table-cell;
    vertical-align: middle;
}

.table td,
.table th {
    border-color: $table-border;
}

.table thead th,
.table th {
    font-weight: 500;
}

.table-hover tbody tr:hover {
    background: $light;
}

.nowrap {
    white-space: nowrap;
}

.lite-padding td {
    padding: 5px;
}

.v-middle {
    td,
    th {
        vertical-align: middle;
    }
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

/*******************
Wave Effects
*******************/

.waves-effect {
    position: relative;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    vertical-align: middle;
    z-index: 1;
    will-change: opacity, transform;
    -webkit-transition: all 0.1s ease-out;
    -moz-transition: all 0.1s ease-out;
    -o-transition: all 0.1s ease-out;
    -ms-transition: all 0.1s ease-out;
    transition: all 0.1s ease-out;
    .waves-ripple {
        position: absolute;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        margin-top: -10px;
        margin-left: -10px;
        opacity: 0;
        background: rgba(0, 0, 0, 0.2);
        -webkit-transition: all 0.7s ease-out;
        -moz-transition: all 0.7s ease-out;
        -o-transition: all 0.7s ease-out;
        -ms-transition: all 0.7s ease-out;
        transition: all 0.7s ease-out;
        -webkit-transition-property: -webkit-transform, opacity;
        -moz-transition-property: -moz-transform, opacity;
        -o-transition-property: -o-transform, opacity;
        transition-property: transform, opacity;
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
        transform: scale(0);
        pointer-events: none;
    }
}

.waves-effect.waves-light {
    .waves-ripple {
        background-color: rgba(255, 255, 255, 0.45);
    }
}

.waves-effect.waves-red {
    .waves-ripple {
        background-color: rgba(244, 67, 54, 0.7);
    }
}

.waves-effect.waves-yellow {
    .waves-ripple {
        background-color: rgba(255, 235, 59, 0.7);
    }
}

.waves-effect.waves-orange {
    .waves-ripple {
        background-color: rgba(255, 152, 0, 0.7);
    }
}

.waves-effect.waves-purple {
    .waves-ripple {
        background-color: rgba(156, 39, 176, 0.7);
    }
}

.waves-effect.waves-green {
    .waves-ripple {
        background-color: rgba(76, 175, 80, 0.7);
    }
}

.waves-effect.waves-teal {
    .waves-ripple {
        background-color: rgba(0, 150, 136, 0.7);
    }
}

html body .waves-notransition {
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    -ms-transition: none;
    transition: none;
}

.waves-circle {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    text-align: center;
    width: 2.5em;
    height: 2.5em;
    line-height: 2.5em;
    border-radius: 50%;
    -webkit-mask-image: none;
}

.waves-input-wrapper {
    border-radius: 0.2em;
    vertical-align: bottom;
    .waves-button-input {
        position: relative;
        top: 0;
        left: 0;
        z-index: 1;
    }
}

.waves-block {
    display: block;
}

/*Badge*/

.badge {
    font-weight: 400;
}

.badge-xs {
    font-size: 9px;
}

.badge-xs,
.badge-sm {
    -webkit-transform: translate(0, -2px);
    -ms-transform: translate(0, -2px);
    -o-transform: translate(0, -2px);
    transform: translate(0, -2px);
}

.badge-success {
    background-color: $success;
}

.badge-info {
    background-color: $info;
}

.badge-primary {
    background-color: $primary;
}

.badge-warning {
    background-color: $warning;
}

.badge-danger {
    background-color: $red-dark;
}

.badge-purple {
    background-color: $purple;
}

.badge-red {
    background-color: $red;
}

.badge-inverse {
    background-color: $inverse;
}

/*******************
Buttons
******************/

.btn {
    padding: 7px 12px;
    cursor: pointer;
}

.btn-group {
    label {
        color: $white !important;
        margin-bottom: 0px;
        &.btn-secondary {
            color: $bodytext !important;
        }
    }
}

.btn-lg {
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
}

.btn-md {
    padding: 12px 55px;
    font-size: 16px;
}

.btn-circle {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    padding: 10px;
}

.btn-circle.btn-sm {
    width: 35px;
    height: 35px;
    padding: 8px 10px;
    font-size: 14px;
}

.btn-circle.btn-lg {
    width: 50px;
    height: 50px;
    padding: 14px 15px;
    font-size: 18px;
    line-height: 22px;
}

.btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    padding: 14px 15px;
    font-size: 24px;
}

.btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 12px;
}

.btn-xs {
    padding: 0.25rem 0.5rem;
    font-size: 10px;
}

.button-list button,
.button-list a {
    margin: 5px 12px 5px 0;
}

.btn-outline {
    color: inherit;
    background-color: transparent;
    transition: all 0.5s;
}

.btn-rounded {
    border-radius: 60px;
    padding: 7px 18px;
    &.btn-lg {
        padding: 0.75rem 1.5rem;
    }
    &.btn-sm {
        padding: 0.25rem 0.5rem;
        font-size: 12px;
    }
    &.btn-xs {
        padding: 0.25rem 0.5rem;
        font-size: 10px;
    }
    &.btn-md {
        padding: 12px 35px;
        font-size: 16px;
    }
}

.btn-secondary,
.btn-secondary.disabled {
    box-shadow: $default-shadow;
    transition: 0.2s ease-in;
    background-color: $white;
    color: $bodytext;
    border-color: $form-brd;
    &:hover {
        box-shadow: $default-shadow-hover;
    }
    &.active,
    &:active,
    &:focus {
        box-shadow: $default-shadow-hover;
    }
}

.btn-primary,
.btn-primary.disabled {
    background: $primary;
    border: 1px solid $primary;
    box-shadow: $primary-shadow;
    transition: 0.2s ease-in;
    &:hover {
        background: $primary;
        box-shadow: $primary-shadow-hover;
        border: 1px solid $primary;
    }
    &.active,
    &:active,
    &:focus {
        background: $primary-dark;
        box-shadow: $primary-shadow-hover;
    }
}

.btn-themecolor,
.btn-themecolor.disabled {
    background: $themecolor;
    color: $white;
    border: 1px solid $themecolor;
    &:hover {
        background: $themecolor;
        opacity: 0.7;
        border: 1px solid $themecolor;
    }
    &.active,
    &:active,
    &:focus {
        background: $info-light;
    }
}

.btn-success,
.btn-success.disabled {
    background: #2d6fc6 !important;
    border: 1px solid #2d6fc6 !important;
    box-shadow: none !important;
    transition: 0.2s ease-in;
    &:hover {
        background: $success;
        box-shadow: $success-shadow-hover;
        border: 1px solid $success;
    }
    &.active,
    &:active,
    &:focus {
        background: $success-dark;
        box-shadow: $success-shadow-hover;
    }
}

.btn-info,
.btn-info.disabled {
    background: $info;
    border: 1px solid $info;
    transition: 0.2s ease-in;
    color: $textthemecolor;
    font-weight: 500;
    &:hover {
        background: $info-light;
        border: 1px solid $info-light;
        color: $white !important;
        font-weight: 500;
    }
    &.active,
    &:active,
    &:focus {
        background: $info-light;
        color: $white !important;
    }
}

.btn-warning,
.btn-warning.disabled {
    background: $warning;
    box-shadow: $warning-shadow;
    border: 1px solid $warning;
    transition: 0.2s ease-in;
    color: $white;
    &:hover {
        background: $warning;
        color: $white;
        box-shadow: $warning-shadow-hover;
        border: 1px solid $warning;
    }
    &.active,
    &:active,
    &:focus {
        background: $warning-dark;
        color: $white;
        box-shadow: $warning-shadow-hover;
    }
}

.btn-danger,
.btn-danger.disabled {
    background: $info;
    border: 1px solid $info;
    transition: 0.2s ease-in;
    color: $textthemecolor;
    &:hover {
        background: $info-light;
        border: 1px solid $info-light;
        color: $white !important;
    }
    &.active,
    &:active,
    &:focus {
        background: $info-light !important;
        color: $white !important;
    }
}

.btn-inverse,
.btn-inverse.disabled {
    background: $inverse;
    border: 1px solid $inverse;
    color: $white;
    &:hover {
        background: $inverse;
        opacity: 0.7;
        color: $white;
        border: 1px solid $inverse;
    }
    &.active,
    &:active,
    &:focus {
        background: $inverse-dark;
        color: $white;
    }
}

.btn-red,
.btn-red.disabled {
    background: $red;
    border: 1px solid $red;
    color: $white;
    &:hover {
        opacity: 0.7;
        border: 1px solid $red;
        background: $red;
    }
    &.active,
    &:active,
    &:focus {
        background: $danger-dark;
    }
}

.btn-outline-secondary {
    background-color: $white;
    box-shadow: $default-shadow;
    transition: 0.2s ease-in;
    &:hover,
    &:focus,
    &.focus {
        box-shadow: $default-shadow-hover;
    }
    &.active,
    &:active,
    &:focus {
        box-shadow: $default-shadow-hover;
    }
}

.btn-outline-primary {
    color: $primary;
    background-color: $white;
    border-color: $primary;
    box-shadow: $primary-shadow;
    transition: 0.2s ease-in;
    &:hover,
    &:focus,
    &.focus {
        background: $primary;
        box-shadow: $primary-shadow-hover;
        color: $white;
        border-color: $primary;
    }
    &.active,
    &:active,
    &:focus {
        box-shadow: $primary-shadow-hover;
        background: $primary-dark;
    }
}

.btn-outline-success {
    color: $success;
    background-color: transparent;
    border-color: $success;
    box-shadow: $success-shadow;
    transition: 0.2s ease-in;
    &:hover,
    &:focus,
    &.focus {
        background: $success;
        border-color: $success;
        color: $white;
        box-shadow: $success-shadow-hover;
    }
    &.active,
    &:active,
    &:focus {
        box-shadow: $success-shadow-hover;
        background: $success-dark;
    }
}

.btn-outline-info {
    color: $textthemecolor;
    background-color: $white;
    border: 1px solid $themecolor;
    transition: 0.2s ease-in;
    &:hover,
    &:focus,
    &.focus {
        background: $themecolor !important;
        border-color: $themecolor !important;
        color: $textthemecolor !important;
    }
    &.active,
    &:active,
    &:focus {
        background: $themecolor !important;
        color: $textthemecolor !important;
    }
}

.btn-outline-warning {
    color: $warning;
    background-color: transparent;
    border-color: $warning;
    box-shadow: $warning-shadow;
    transition: 0.2s ease-in;
    &:hover,
    &:focus,
    &.focus {
        background: $warning;
        border-color: $warning;
        color: $white;
        box-shadow: $warning-shadow-hover;
    }
    &.active,
    &:active,
    &:focus {
        box-shadow: $warning-shadow-hover;
        background: $warning-dark;
    }
}

.btn-outline-danger {
    color: $danger;
    background-color: transparent;
    border-color: $danger;
    box-shadow: $danger-shadow;
    transition: 0.2s ease-in;
    &:hover,
    &:focus,
    &.focus {
        background: $danger;
        border-color: $danger;
        color: $white;
        box-shadow: $danger-shadow-hover;
    }
    &.active,
    &:active,
    &:focus {
        box-shadow: $danger-shadow-hover;
        background: $danger-dark;
    }
}

.btn-outline-red {
    color: $red;
    background-color: transparent;
    border-color: $red;
    &:hover,
    &:focus,
    &.focus {
        background: $red;
        border-color: $red;
        color: $white;
        box-shadow: $danger-shadow-hover;
    }
    &.active,
    &:active,
    &:focus {
        box-shadow: $danger-shadow-hover;
        background: $danger-dark;
    }
}

.btn-outline-inverse {
    color: $inverse;
    background-color: transparent;
    border-color: $inverse;
    &:hover,
    &:focus,
    &.focus {
        background: $inverse;
        border-color: $inverse;
        color: $white;
    }
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover,
.btn-primary.focus,
.btn-primary:focus {
    background-color: $primary-dark;
    border: 1px solid $primary-dark;
}

.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success.focus:active,
.btn-success:active:focus,
.btn-success:active:hover,
.open > .dropdown-toggle.btn-success.focus,
.open > .dropdown-toggle.btn-success:focus,
.open > .dropdown-toggle.btn-success:hover,
.btn-success.focus,
.btn-success:focus {
    background-color: $success-dark;
    border: 1px solid $success-dark;
}

.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info.focus:active,
.btn-info:active:focus,
.btn-info:active:hover,
.open > .dropdown-toggle.btn-info.focus,
.open > .dropdown-toggle.btn-info:focus,
.open > .dropdown-toggle.btn-info:hover,
.btn-info.focus,
.btn-info:focus {
    background-color: $info-dark;
    border: 1px solid $info-dark;
}

.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning.focus:active,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open > .dropdown-toggle.btn-warning.focus,
.open > .dropdown-toggle.btn-warning:focus,
.open > .dropdown-toggle.btn-warning:hover,
.btn-warning.focus,
.btn-warning:focus {
    background-color: $warning-dark;
    border: 1px solid $warning-dark;
}

.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger.focus:active,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open > .dropdown-toggle.btn-danger.focus,
.open > .dropdown-toggle.btn-danger:focus,
.open > .dropdown-toggle.btn-danger:hover,
.btn-danger.focus,
.btn-danger:focus {
    border: 0px;
    box-shadow: none !important;
}

.btn-inverse.active,
.btn-inverse.focus,
.btn-inverse:active,
.btn-inverse:focus,
.btn-inverse:hover,
.open > .dropdown-toggle.btn-inverse {
    background-color: $inverse-dark;
    border: 1px solid $inverse-dark;
}

.btn-red.active,
.btn-red.focus,
.btn-red:active,
.btn-red:focus,
.btn-red:hover,
.open > .dropdown-toggle.btn-red {
    background-color: $red-dark;
    border: 1px solid $red-dark;
    color: $white;
}

.button-box .btn {
    margin: 0 8px 8px 0px;
}

.btn-label {
    background: rgba(0, 0, 0, 0.05);
    display: inline-block;
    margin: -6px 12px -6px -14px;
    padding: 7px 15px;
}

.btn-facebook {
    color: $white;
    background-color: #3b5998;
}

.btn-twitter {
    color: $white;
    background-color: #55acee;
}

.btn-linkedin {
    color: $white;
    background-color: #007bb6;
}

.btn-dribbble {
    color: $white;
    background-color: #ea4c89;
}

.btn-googleplus {
    color: $white;
    background-color: #dd4b39;
}

.btn-instagram {
    color: $white;
    background-color: #3f729b;
}

.btn-blog {
    color: $white !important;
    background-color: #ff8000;
}

.btn-pinterest {
    color: $white;
    background-color: #cb2027;
}

.btn-dropbox {
    color: $white;
    background-color: #007ee5;
}

.btn-flickr {
    color: $white;
    background-color: #ff0084;
}

.btn-tumblr {
    color: $white;
    background-color: #32506d;
}

.btn-skype {
    color: $white;
    background-color: #00aff0;
}

.btn-youtube {
    color: $white;
    background-color: #bb0000;
}

.btn-github {
    color: $white;
    background-color: #171515;
}

/*******************
Notify
*******************/

.notify {
    position: relative;
    top: -22px;
    right: -9px;
    .heartbit {
        position: absolute;
        top: -20px;
        right: -4px;
        height: 25px;
        width: 25px;
        z-index: 10;
        border: 5px solid $danger;
        border-radius: 70px;
    }
    .point {
        width: 6px;
        height: 6px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        border-radius: 30px;
        background-color: $danger;
        position: absolute;
        right: 6px;
        top: -10px;
    }
}

/*******************
File Upload 
******************/

.fileupload {
    overflow: hidden;
    position: relative;
    input.upload {
        cursor: pointer;
        filter: alpha(opacity=0);
        font-size: 20px;
        margin: 0;
        opacity: 0;
        padding: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
}

/*******************
Megamenu
******************/

.mega-dropdown {
    position: static !important;
    width: 100%;
    .dropdown-menu {
        width: 100%;
        padding: 30px;
        margin-top: 0px;
        left: 0px !important;
    }
    ul {
        padding: 0px;
        li {
            list-style: none;
        }
    }
    .carousel-item .container {
        padding: 0px;
    }
    .nav-accordion {
        .card {
            margin-bottom: 1px;
        }
        .card-header {
            background: $white;
            h5 {
                margin: 0px;
                a {
                    text-decoration: none;
                    color: $bodytext;
                }
            }
        }
    }
}

/*******************
List-style-none
******************/

ul.list-style-none {
    margin: 0px;
    padding: 0px;
    li {
        list-style: none;
        a {
            color: $bodytext;
            padding: 8px 0px;
            display: block;
            text-decoration: none;
            &:hover {
                color: $themecolor;
            }
        }
    }
}

/*******************
dropdown-item
******************/

.dropdown-item {
    padding: 8px 1rem;
    color: $bodytext;
}

/*******************
Custom-select
******************/

.custom-select {
    background: url(../../assets/images/custom-select.png) right 0.75rem center no-repeat;
}

/*******************
textarea
******************/

textarea {
    resize: none;
}

/*******************
Form-control
******************/

.form-control {
    color: $bodytext;
    min-height: 38px;
    display: initial;
}

.form-control-sm {
    min-height: 20px;
}

.form-control:disabled,
.form-control[readonly] {
    opacity: 1;
}

.custom-control-input:focus ~ .custom-control-indicator {
    box-shadow: none;
}

.custom-control-input:checked ~ .custom-control-indicator {
    background-color: $success;
}

form label {
    font-weight: 400;
}

.form-group {
    margin-bottom: 25px;
}

.form-horizontal label {
    margin-bottom: 0px;
}

.form-control-static {
    padding-top: 0px;
}

.form-bordered .form-group {
    border-bottom: 1px solid $border;
    padding-bottom: 20px;
}

/*******************
Layouts
*****************/

/*Card-noborders*/

.card-no-border {
    .card {
        border: 0px;
        border-radius: $radius;
        box-shadow: none;
    }
}

.card-no-border .shadow-none {
    box-shadow: none;
}

.card-outline-danger,
.card-outline-info,
.card-outline-warning,
.card-outline-success,
.card-outline-primary {
    background: $white;
}

.card-fullscreen {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    overflow: auto;
}

/*******************/

/*widgets -app*/

/*******************/

.css-bar:after {
    z-index: 1;
}

.css-bar > i {
    z-index: 10;
}

/*******************/

/*single column*/

/*******************/

.single-column {
    .left-sidebar {
        display: none;
    }
    .page-wrapper {
        margin-left: 0px;
        -webkit-overflow-scrolling: touch;
    }
}

.fix-width {
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
}

/*******************/

/*card-default*/

/*******************/

.card-default {
    .card-header {
        background: $white;
        border-bottom: 0px;
    }
}

/*******************/

/*pace-js*/

/*******************/

.pace {
    -webkit-pointer-events: none;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.pace-inactive {
    display: none;
}

.pace .pace-progress {
    background: $info;
    position: fixed;
    z-index: 2000;
    top: 0;
    right: 100%;
    width: 100%;
    height: 2px;
}

.progress {
    height: auto;
    min-height: 6px;
}

.no-control-indicator {
    .carousel-indicators,
    .carousel-control-prev,
    .carousel-control-next {
        display: none;
    }
}

.lstick {
    width: 3px;
    background: $themecolor;
    height: 30px;
    margin-left: -16px;
    margin-right: 18px;
    display: inline-block;
    vertical-align: middle;
}

.btn-post-new {
    background: #3e70c0;
    border: 1px solid #3e70c0;
    -webkit-transition: 0.2s ease-in;
    transition: 0.2s ease-in;
    color: #fff !important;
}

.bg-dark-blue {
    color: #fff !important;
    background: #152b50 !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.btn-post-new:hover {
    background-color: #152b50;
    border: 1px solid #152b50;
}

.btn-post-cancel {
    background: #c4d3ec;
    border: 1px solid #c4d3ec;
    -webkit-transition: 0.2s ease-in;
    transition: 0.2s ease-in;
    color: #fff !important;
}

.btn-info-new {
    background: #3e70c0;
    border: 1px solid #3e70c0;
    -webkit-transition: 0.2s ease-in;
    transition: 0.2s ease-in;
    color: #fff !important;
    width: 90px;
}

.btn-light-new {
    background: #c4d3ec;
    border: 1px solid #c4d3ec;
    -webkit-transition: 0.2s ease-in;
    transition: 0.2s ease-in;
    color: #fff !important;
    width: 90px;
}

.btn-weight-600 {
    font-weight: 600 !important;
    &:hover {
        font-weight: 600 !important;
    }
}

.my-new-tabset ngb-tabset ul.nav-tabs {
    position: sticky !important;
    top: 0 !important;
    z-index: 1020 !important;
    background: white !important;
}
.new-show-less {
    color: #152b50 !important;
    font-weight: 600;
}
.new-show-less:hover {
    color: #91abd6 !important;
    text-decoration: none;
}

.dropdown-list ul li:hover {
    background: #dee2e6 !important;
}

.modal-body {
    background-color: transparent;
}

.mytooltip2 {
    left: -35px !important;
}

.mytooltip2 .tooltip-inner {
    width: 96px;
    text-align: left;
    background-color: #000;
    color: #fff;
    box-shadow: 0 0 3px #00000040;
}

.privacy_tooltip .tooltip-inner {
    width: 74px;
    text-align: left;
    background-color: #000;
    color: #fff;
    box-shadow: 0 0 3px #00000040;
}
.md-drppicker {
    width: 605px !important;
}

.md-drppicker td.active {
    background-color: #3e70c0 !important;
}

.md-drppicker .ranges ul li button.active {
    background-color: #3e70c0 !important;
}

.md-drppicker .btn {
    background-color: #3e70c0 !important;
    padding: 0 10px !important;
}

.md-drppicker .btn:hover {
    background-color: #152b50 !important;
}

.md-drppicker .clear:hover {
    background-color: #fff !important;
}

.md-drppicker .clear svg:hover {
    color: #cacaca !important;
}

ngx-guided-tour .tour-step .tour-block {
    margin: 5px 8px !important;
}

ngx-guided-tour .tour-step .tour-buttons button.link-button {
    position: absolute !important;
}

ngx-guided-tour .tour-step .tour-buttons button.skip-button {
    color: #152b50 !important;
    background-color: #c4d4ec !important;
    top: 11% !important;
    right: 15% !important;
    font-weight: 500 !important;
    padding: 2px 6px !important;
    border-radius: 5px;
    font-size: 13px !important;
}

ngx-guided-tour .tour-step .tour-buttons button.skip-button:hover {
    background-color: #d9d9d9 !important;
}

ngx-guided-tour .tour-step .tour-buttons .back-button {
    background-color: #d1d1d1 !important;
    color: #575757 !important;
    border-radius: 15px !important;
    width: 80px !important;
    padding: 2px 10px !important;
}

ngx-guided-tour .tour-step .tour-buttons .back-button:hover {
    background-color: #f2efef !important;
}

ngx-guided-tour .tour-step .tour-buttons .next-button {
    background-color: #3e70c0 !important;
    color: #fff !important;
    border-radius: 15px !important;
    width: 90px !important;
    padding: 4px 10px !important;
}

ngx-guided-tour .tour-step .tour-buttons .next-button:hover {
    background-color: #152b50 !important;
}

.ng-dropdown-panel {
    z-index: 99999 !important;
}

.emoji-mart-scroll {
    height: 175px !important;
}

.emoji-div-class-post .emoji-mart-scroll {
    height: 161px !important;
}

.ng-select.ng-select-single .ng-select-container {
    height: 38px;
    line-height: 38px !important;
}

.ng-select.ng-select-disabled > .ng-select-container {
    background-color: #e9ecef !important;
    color: #6c757d !important;
}

@media screen and (max-width: 991px) {
    .md-drppicker {
        width: auto !important;
    }
    ngx-guided-tour .tour-step .tour-block {
        margin: 5px 0px !important;
    }
}

@media screen and (min-width: 1025px) {
    .modal-xxl .modal-content {
        height: 100% !important;
    }
}

@media screen and (min-width: 768px) {
    .emoji-div-class-post .emoji-mart-scroll {
        height: 135px !important;
    }
}

.iframeHolder {
    overflow-x: scroll;
}

// .cdk-overlay-pane {
//     top: auto;
// }
