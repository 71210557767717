@import "../variable";
$themecolor: #1d2cbb;
$themecolor-dark: #028ee1;
$topbar: #152b50;
$sidebar: #242a33;
$sidebar-text: #687384;
$sidebar-icons: #424a56;
$sidebar-dark: #1c2128;
$sidebar-alt: #1c2128;
$themecolor-alt: #26c6da;
$topbar-alt: #fd1e50;
.defaultdark {
    /*******************
/*Top bar
*******************/
    .topbar {
        background: $topbar;
        .top-navbar .navbar-header .navbar-brand .light-logo {
            display: none;
        }
        .top-navbar {
            .navbar-nav>.nav-item>span {
                color: $themecolor;
            }
        }
        .navbar-light .navbar-nav .nav-item> {
            a.nav-link {
                color: #2b2b2b;
                &:hover,
                &:focus {
                    color: #242a33;
                }
            }
        }
        .sidebartoggler {
            color: #fff !important;
            width: 35px;
            height: 35px;
            border-radius: 50px;
            background: $info;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            &:hover,
            &:focus {
                color: #fff;
            }
        }
    }
    .logo-center .topbar {
        .navbar-header {
            text-align: center;
            padding-left: 0px;
            box-shadow: none;
        }
        .top-navbar .navbar-header .navbar-brand .dark-logo {
            display: none;
        }
        .top-navbar .navbar-header .navbar-brand .light-logo {
            display: inline-block;
            color: rgba(255, 255, 255, 0.8);
        }
    }
    /*******************
/*General Elements
*******************/
    a.link {
        &:hover,
        &:focus {
            color: $themecolor !important;
        }
    }
    .right-sidebar {
        .rpanel-title {
            background: $themecolor;
        }
    }
    .text-themecolor {
        color: $themecolor !important;
    }
    /*******************
/*Buttons
*******************/
    .btn-themecolor,
    .btn-themecolor.disabled {
        background: $themecolor;
        color: $white;
        border: 1px solid $themecolor;
        &:hover {
            background: $themecolor;
            opacity: 0.7;
            border: 1px solid $themecolor;
        }
        &.active,
        &:focus {
            background: $themecolor-dark;
        }
    }
    /*******************
/*sidebar navigation
*******************/
    .left-sidebar,
    .card-no-border .left-sidebar,
    .card-no-border .sidebar-nav {
        background: $sidebar;
    }
    .user-profile .profile-text a {
        color: $sidebar-text !important;
    }
    .card-no-border .sidebar-footer {
        background: $sidebar-dark;
    }
    .label-themecolor {
        background: $themecolor;
    }
    .sidebar-nav>ul>li.active>a {
        color: $themecolor;
        border-color: $themecolor;
        i {
            color: $themecolor;
        }
    }
    .sidebar-nav ul li a {
        &.router-link-active,
        &:hover {
            color: #d0e0f9;
            i {
                color: #d0e0f9;
            }
        }
    }
    .sidebar-nav ul li.nav-small-cap {
        color: $sidebar-text;
    }
    @media (min-width: 768px) {
        &.mini-sidebar .sidebar-nav #sidebarnav>li>ul {
            background: $sidebar-alt;
        }
        &.mini-sidebar .sidebar-nav #sidebarnav>li:hover>a {
            background: $sidebar-alt;
        }
    }
}