/*============================================================== 
 For Laptop & above all (1650px) 
 ============================================================== */

 @media (min-width: 1650px) {
    .widget-app-columns {
        column-count: 3;
    }
}


/*============================================================== 
 For Laptop & above all (1370px) 
 ============================================================== */

@media (max-width: 1370px) {
    .widget-app-columns {
        column-count: 2;
    }
}


/*-- ============================================================== 
 Small Desktop & above all (1024px) 
 ============================================================== */

@media (min-width: 1024px) {
    .page-wrapper {
        margin-left: 0px;
    }
    .footer {
        left: 0px;
    }
}

@media (max-width: 1023px) {
    .widget-app-columns {
        column-count: 1;
    }
}


/*-- ============================================================== 
 Ipad & above all(768px) 
 ============================================================== */

@media (min-width: 768px) {
    .navbar-header {
        flex-shrink: 0;
        .navbar-brand {
            padding-top: 0px;
        }
    }
    /*This is for the breeadcrumd*/
    .card-group .card:first-child,
    .card-group .card:not(:first-child):not(:last-child) {
        border-right: 1px solid rgba(0, 0, 0, 0.03);
    }
    .material-icon-list-demo .icons div {
        width: 33%;
        padding: 15px;
        display: inline-block;
        line-height: 40px;
    }
    .mini-sidebar {
        .page-wrapper {
            margin-left: 0px;
        }
        .footer {
            left: 0px;
        }
    }
    .flex-wrap {
        flex-wrap: nowrap !important;
        -webkit-flex-wrap: nowrap !important;
    }
    .navbar-expand-md .navbar-nav .dropdown-menu-right {
        left: auto !important;
    }
    .navbar-expand-md .sm-hide {
        display: none;
    }
}

/*-- ============================================================== 
 Phone and below ipad(767px) 
 ============================================================== */

@media (max-width: 767px) {
    /*Header*/
    .topbar {
        width: 100%;
        position: fixed !important;
        .top-navbar {
            padding-bottom: 0px;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            -webkit-align-items: center;
            .navbar-collapse {
                display: flex;
                flex-basis: auto;
            }
            .navbar-brand span {
                display: none;
            }
            .navbar-nav {
                flex-direction: row;
                >.nav-item.show {
                    position: static;
                    .dropdown-menu {
                        width: 100%;
                        margin-top: 0px;
                        left: 0 !important;
                        right: 0 !important;
                    }
                }
                >.nav-item>.nav-link {
                    padding-left: 1px !important;
                    padding-right: 1px !important;
                }
                .dropdown-menu {
                    position: absolute;
                }
            }
        }
    }
    .mega-dropdown {
        .dropdown-menu {
            height: 480px;
            overflow: auto;
        }
    }
    /*Sidebar and wrapper*/
    .mini-sidebar .page-wrapper {
        margin-left: 0px;
    }
    .comment-text {
        .comment-footer {
            .action-icons {
                display: block;
                padding: 10px 0;
            }
        }
    }
    /*Footer*/
    .footer {
        left: 0px;
        position: relative;
    }
    .material-icon-list-demo .icons div {
        width: 100%;
    }
    .error-page {
        .footer {
            position: fixed;
            bottom: 0px;
            z-index: 10;
        }
    }
    .error-box {
        position: relative;
        padding-bottom: 60px;
    }
    .error-body {
        padding-top: 10%;
        h1 {
            font-size: 100px;
            font-weight: 600;
            line-height: 100px;
        }
    }
    .login-register {
        position: relative;
        overflow: hidden;
    }
    .login-box {
        width: 90%;
    }
    .login-sidebar {
        padding: 10% 0;
        .login-box {
            position: relative;
        }
    }
    /*Timeline*/
    ul.timeline:before {
        left: 40px;
    }
    ul.timeline>li>.timeline-badge {
        top: 16px;
        left: 15px;
        margin-left: 0;
    }
    ul.timeline>li>.timeline-panel {
        width: calc(100% - 90px);
        float: right;
    }
    ul.timeline>li>.timeline-panel:before {
        right: auto;
        left: -15px;
        border-right-width: 15px;
        border-left-width: 0;
    }
    ul.timeline>li>.timeline-panel:after {
        right: auto;
        left: -14px;
        border-right-width: 14px;
        border-left-width: 0;
    }
    .followTabs li a {
        font-size: 12px;
    }
    .fb-profile img.fb-image-lg {
        height: 150px;
    }
    .cover-center {
        position: relative;
        width: 100%;
        top: 0px;
    }
    .sb {
        display: none;
    }
    .w-sm-100 {
        width: 100% !important;
    }
    .page-wrapper {
        padding: 0px;
        padding-top: 110px;
        padding-bottom: 0px;
    }
    #main-wrapper {
        height: 100%;
    }
    .footer {
        position: static;
    }
}

@media screen and (min-width:320px) and (max-width:992px) and (orientation:landscape) {

   
}




/*------------- Comment by Tarun----------------- */
@media(max-width:992px){
    .page-wrapper {
        padding-top:135px !important;
     
    }
}
@media(max-width:768px){
    .page-wrapper {
        padding-top:135px;
     
    }
}
@media (max-width: 576px) {
     
    .page-wrapper {
        padding-top:70px !important;
     
    }
}