/* You can add global styles to this file, and also import other style files */

:root {
    --redColor: #152B50;
}

.ps .ps__scrollbar-y-rail {
    left: 3px;
    right: auto;
}

html {
    height: 100%;
    height: webkit-fill-available;
}

body {
    min-height: 100%;
    min-height: webkit-fill-available;
    font-weight: 500;
    color: #191919;
}

.header-title,
.sub-header-block {
    background-color: var(--redColor) !important;
}

.list-group-item {
    border-bottom: 1px solid transparent !important;
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    border-radius: 0px !important;
    position: relative;
    display: block;
    padding: 0px !important;
    margin-bottom: -1px;
    background-color: #fff;
}

.list-group-item a {
    color: #575757;
    font-weight: 400;
    display: block;
    padding: 7px 15px;
    transition: 0.2s ease-in-out;
    font-size: 13px;
}

.list-group-item a:hover {
    color: #575757;
    background: #f4f6f9;
}

.mdi-2x {
    font-size: 1.3rem;
}

.media {
    border: none !important;
    padding: 0 !important;
    margin-bottom: 0 !important;
}

.media textarea {
    border: 1px solid #ebebeb;
}

.form-control:focus {
    box-shadow: none !important;
}

.form-control {
    color: #67757c;
    min-height: 38px;
    display: initial;
}

.topbar .form-control {
    border-radius: 2px !important;
    background-color: #fafafa !important;
    border: 1px solid rgb(206, 212, 218, 0.3) !important;
}

.action-tool-btn a {
    font-size: 1rem;
    padding: 0 8px;
    font-weight: 500;
    text-align: center;
    display: flex;
    align-items: center;
    transition: 0.2s ease-in-out;
    color: #2d368e;
}

.like_button_text {
    font-size: 1rem;
    font-weight: 500;
    transition: 0.2s ease-in-out;
    color: #fff !important;
}

.action-tool-btn img {
    margin-right: 5px;
}

.action-tool-btn ul a {
    font-size: 0.875rem;
    color: #67757c;
}

p {
    font-size: 13px;
    white-space: pre-wrap;
}

.postImg {
    width: 100%;
    overflow: hidden;
}

.blog-icon {
    padding-top: 1px;
}

.blog-icon img {
    width: 35px;
}

.blog-icon .blog-img {
    width: 35px;
}

.postImg img {
    background-position: center;
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.logo {
    color: #ffffff;
    font-weight: 500;
}

.row-xs>div {
    padding-right: 0.425rem;
    padding-left: 0.425rem;
}

.container {
    padding: 15px;
}

.divHeight {
    height: 100%;
}

.card-body {
    padding: 1rem !important;
}

.search {
    position: relative;
    border: 1px solid #f4f6f9;
    background: #ffffff;
    height: fit-content;
}

.search_width {
    width: 250px !important;
}

.search input {
    background: transparent;
    border: 0px none;
    outline: none;
}

.search span {
    position: absolute;
    top: 4px;
    right: 10px;
}

.navbar-light .navbar-nav .nav-link {
    color: #282828 !important;
}

.navbar-light .navbar-nav .nav-link img.rounded-circle {
    filter: invert(0);
    border-radius: 0.25rem !important;
}



.w-twitter li {
    padding: 15px 0;
    border-bottom: 1px solid #e9edf2;
}

.w-twitter .author-folder {
    margin-bottom: 12px;
}

.w-twitter .author-folder img {
    margin-right: 8px;
}

.w-twitter .author-folder>* {
    display: inline-block;
    vertical-align: middle;
}

.w-twitter .author a {
    line-height: 1;
}

.w-twitter .author-name {
    display: block;
    font-weight: 500;
    color: #455a64;
}

.w-twitter .group {
    font-size: 11px;
}

.w-twitter {
    list-style: none;
    padding: 0;
    margin: 0px;
}

.w-twitter .post__date {
    font-size: 12px;
}

.w-twitter li:last-child {
    border-bottom: none;
}

.dropdown-privacy {
    list-style: none;
    padding: 0;
    margin: 0px;
}

.ui-block {
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #e6ecf5;
    margin-bottom: 15px;
}

.w-action {
    padding: 40px 25px 40px;
    text-align: center;
    color: #fff;
    border-radius: 4px;
}

.w-action img,
.w-action i {
    margin-bottom: 15px;
    font-size: 35px;
}

.w-action .content .title {
    font-weight: 400;
    color: inherit;
    margin-bottom: 15px;
    font-size: 24px;
}

.w-action .content span {
    font-size: 10px;
    margin-bottom: 25px;
    display: block;
    text-transform: uppercase;
}

.w-action .btn {
    margin-bottom: 0;
}

.btn-bg-secondary {
    background-color: #3f4257;
    color: #ffffff;
}

.btn-md {
    padding: 1rem 3.5rem;
    font-size: 0.75rem;
    border-radius: 0.3rem;
}

.sticky {
    position: sticky !important;
    position: -webkit-sticky !important;
    top: 78px;
    z-index: 9;
}


.dropdown-item.active,
.dropdown-item:active {
    background-color: #c4d4ec !important;
}

.read-more {
    position: absolute;
    right: 0;
    left: 0;
    text-align: center;
    width: 100px;
    height: 27px;
    line-height: 29px;
    background: #e7eaee;
    border-radius: 100px;
    bottom: -28px;
    margin: auto;
    z-index: 1;
    font-size: 12px;
}

.read-more span {
    width: 100%;
}

a {
    font-size: 14px;
    font-weight: 500;
}



.fb-profile img.fb-image-lg {
    z-index: 0;
    width: 100%;
    margin-bottom: 10px;
    height: 352px;
    object-fit: cover;
}

.fb-profile .fb-image-loader-lg {
    z-index: 0;
    width: 100%;
    margin-bottom: 10px;
    height: 352px;
    object-fit: cover;
}

.img-circle-loader {
    height: 150px !important;
    width: 150px !important;
}

.fb-image-profile {
    margin: -100px 10px 0px 50px;
    z-index: 9;
    width: 200px;
    height: 200px;
    display: inline-block;
    position: relative;
}

.fb-image-profile a {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    background: #2d6fc6;
    color: #fff !important;
    border-radius: 50px;
}

.image-upload>input {
    display: none;
}

.cover-edit {
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;
}

.cover-edit a {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    background: #2d6fc6;
    color: #fff !important;
    border-radius: 50px;
}

@media (max-width: 576px) {
    .catBox:hover {
        background: transparent !important;
    }

    .fb-image-profile {
        z-index: 9;
        margin: -100px auto 0px auto !important;
        display: block !important;
    }

    .fb-image-profile img {
        width: 120px;
        margin: auto;
        float: none;
        display: block;
    }

    .fb-profile-text {
        clear: both;
    }

    .fb-profile-text>h3 {
        float: left;
        width: 100%;
        text-align: center;
    }

    .fb-profile-text>p {
        float: left;
        width: 100%;
        text-align: center;
        margin: 10px 0;
    }

    .fb-image-profile a {
        right: 35px;
    }

    .sticky {
        position: static !important;
        position: -webkit-static !important;
        top: 0;
        z-index: 9;
    }
}

@media (max-width: 768px) {
    .fb-profile img.fb-image-lg {
        height: 250px;
    }

    .fb-image-profile {
        z-index: 9;
        margin: -100px auto 0px 8px;
        display: block;
        width: 150px;
        height: 150px;
    }

    .fb-image-profile img {
        width: 120px !important;
        margin: auto;
        float: none;
        display: block;
    }

    .fb-profile-text {
        clear: both;
    }

    .fb-profile-text>h3 {
        font-weight: 600;
        font-size: 16px;
    }
}

.blog-icon-signup {
    width: 27px;
    position: relative;
    top: 2px;
    left: 10px;
}

@media (max-width: 767px) {
    .blog-icon .blog-img {
        width: 25px;
        position: relative;
        bottom: 5px;
    }

    .fb-image-profile {
        width: 150px;
        height: 150px;
    }

    .ft-10 {
        font-size: 10px !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    footer li a.p-2 {
        display: block;
        color: initial;
        padding: 3px 2px 0px 0px !important;
    }

    footer li a.p-2:last-child {
        padding-top: 0px !important;
    }

    footer li a.p-2:first-child {
        padding-top: 0px !important;
    }

    footer li {
        display: block;
        margin: 2px !important;
    }

    footer li a {
        font-size: 12px !important;
    }

    footer ul {
        margin-top: 8px;
        margin-bottom: 8px !important;
        white-space: nowrap;
        overflow-x: scroll;
    }
}

.navBtn ul {
    padding: 0px;
    margin: 0px;
}

.navBtn ul li {
    display: block;
    list-style: none;
}

.navBtn ul li a {
    display: block;
    padding: 8px 15px;
    font-size: 1rem;
}

.navBtn ul li a:hover {
    background: #f4f6f9;
}

.navBtn ul li a.active {
    background: #f4f6f9;
}

label {
    font-size: 14px !important;
}

input,
select,
textarea {
    font-size: 14px !important;
}

.editMode .form-group .btn {
    opacity: 1;
    transition: 0.2s ease-in-out;
}

@media (max-width: 1024px) {
    .editMode .form-group .btn {
        opacity: 1;
    }
}

.editMode .form-group:hover .btn {
    opacity: 1;
}

.addEdu {
    width: 70px;
    height: 70px;
    line-height: 70px;
    border: 1px dashed #ccc;
    display: inline-block;
    text-align: center;
}

.acc-remove {
    position: absolute;
    top: 0;
    right: 0;
}

.catBox {
    text-align: center;
    display: block;
    padding: 15px;
    border: 1px solid #f4f6f9;
    transition: 0.2s ease-in-out;
}

.catBox:hover {
    background: #f4f6f9;
}

.catBox.active {
    background: #f4f6f9 !important;
}

.notMes li {
    border-bottom: 1px solid #e9edf2;
    border-radius: 0.25rem;
    padding: 15px 0;
    transition: 0.2s ease-in-out;
}

.suggest-list li {
    border-bottom: 1px solid #e9edf2;
    padding: 0px 0px;
    transition: 0.2s ease-in-out;
}

.dropdown-toggle .mdi-dots-horizontal {
    font-size: 25px;
    color: #000;
    line-height: 10px;
}

.dropdown-toggle::after {
    color: #000;
}

.notMes li:last-child {
    border-bottom: 0px transparent;
}

.notMes li:hover {
    background: #dee2e6;
}

.followTabs li a {
    background: #f8f9fa;
    display: block;
    text-align: center;
    padding: 10px;
    font-weight: 500;
    flex-basis: 100%;
}

.followTabs .f-active a {
    background: #c0d4ee !important;
    color: #152B50 !important;
}

a.f-active {
    background: #c0d4ee !important;
    color: #152b50 !important;
    border-radius: 3px 3px 0px 0px;
}

a:hover.f-active,
a:focus.f-active {
    color: #2d368e;
}

.notMes li .action-tool {
    opacity: 0;
    transition: 0.2s ease-in-out;
}

.notMes li:hover .action-tool {
    opacity: 1;
}

.page-item.active .page-link {
    background-color: #c0d4ee !important;
    border-color: #c0d4ee !important;
}

.dropdown-pad-none li {
    padding: 0px;
}

.qa-list li {
    display: block;
    text-align: left;
    border-bottom: 1px solid #f4f6f9;
}

.qa-list li a {
    display: block;
    padding: 10px;
    color: inherit;
    font-weight: 400;
}

.msgBox {
    height: 300px;
    overflow: auto;
}

.iUploadbox {
    text-align: center;
}

.iUploadbox a {
    transition: 0.2s ease-in-out;
}

.iUploadbox a:first-child {
    border-right: 1px solid #f4f6f9;
}

.iUploadbox a:hover {
    background: #f4f6f9;
}

.iUploadbox a:hover span {
    color: #2d6fc6;
}

.commentList {
    padding: 1rem 0;
}

.doComment {
    background: transparent;
}

.doComment textarea {
    background: transparent;
    border-radius: 100px;
}

.cat-int-ht {
    overflow: auto;
}

.topbar {
    background: #152b50;
}

.topbar .top-navbar .navbar-nav>.nav-item>.nav-link {
    text-align: center;
}

.topbar .top-navbar .navbar-nav>.nav-item>.nav-link>small {
    line-height: 1.1 !important;
}

.navigation_name {
    max-width: 120px;
    min-width: 35px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1.4 !important;
}

.mat-form-field-wrapper {
    padding-bottom: 0px !important;
}

.mat-form-field-underline {
    bottom: 0 !important;
}

.showMoreUser {
    display: block;
    position: sticky;
    width: 100%;
    bottom: 0;
    padding: 5px 15px;
    font-weight: 500;
    text-align: center;
}

.scroll-height {
    height: calc(100vh - 200px);
    overflow: auto;

}

.cursor-pointer {
    cursor: pointer;
}

.skew-topbar .nav-item {
    transform: skewX(20deg);
}

.notification-icon>li.active {
    background: transparent;
    border-bottom: 3px solid #152b50;
}

.navbar-light .navbar-nav.notification-icon .active>.nav-link {
    color: #152b50 !important;
}

.navbar-light .navbar-nav.notification-icon .active>.nav-link img {
    filter: invert(0);
}

.notification-icon>li:hover {
    background: transparent;
    border-bottom: 3px solid #152b50;
}

.navbar-light .navbar-nav.notification-icon li:hover a img {
    filter: invert(0);
}

.navbar-light .navbar-nav.notification-icon li:hover a>small {
    color: var(--redColor) !important;
}

.blog-list li.active {
    background: transparent;
    font-weight: 500;
}

.report-hover:hover {
    background: #d0e1f9;
}

.ellipsis_nav {
    max-width: 200px;
    min-width: 35px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ellipsis_side {
    white-space: nowrap;
    min-width: 35px;
    overflow: hidden;
    text-overflow: ellipsis;
}



.obj-fit {
    width: 100px;
    height: 100px;
    object-fit: cover;
}

.chat-obj-fit {
    width: 100px;
    height: 100px;
    object-fit: cover;
}

.close_img {
    right: 5px;
    top: 0px;
    z-index: 9;
    background: rgba(8, 8, 8, 0.411);
    width: 18px;
    height: 18px;
    border-radius: 50px;
    text-align: center;
}

.close_img span {
    font-size: 13px;
}

.moreMediaPost {
    position: absolute;
    top: 0;
    background: rgb(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    overflow: hidden;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
}

.video-fluid {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.video-post-fluid {
    width: 100%;
    max-height: 400px !important;
    object-fit: cover !important;
}

.carousel-control-prev,
.carousel-control-next {
    top: 33% !important;
    height: 8% !important;
    bottom: 75px !important;
}

.videoPlayButton {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    opacity: 0.7;
    color: #fff;
}

.a-font-color {
    color: #2d6fc6 !important;
}

/* this CSS class for short the display Name */

.name_display_limit {
    max-width: 40%;
    min-width: 35px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.previewTestLimit {
    max-width: 80px;
    display: inline-flex;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.name_display_limit_table {
    max-width: 40%;
    min-width: 35px;
    display: table-column;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* this CSS class for short the display Name with inner Block*/

.name_display_limit_inline_block {
    max-width: 25%;
    min-width: 35px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* this CSS class for short the display Name on reply */

.name_display_limit_notification {
    max-width: 50%;
    min-width: 35px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* this CSS class for short the display Name on reply */

.name_display_limit_request {
    max-width: 50%;
    min-width: 35px;
    display: table-cell;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.name_display_limit_setting {
    max-width: 200px;
    min-width: 35px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* This CSS Apply on user Bio */

.truncate_bio {
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
}

/* This CSS Apply on user Bio */

.truncate_username {
    width: 60%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
}

/* This CSS Apply on user Bio in messge */

.truncate_bio_message {
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: break-all;
}

.truncate_blog_title {
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
}

/* This CSS Apply on user Bio in messge */

.modal-body .truncate_post_url {
    width: 100%;
}

.truncate_post_url {
    width: 80%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
}

.truncate_anchor {
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3 !important;
    line-clamp: 3 !important;
    -webkit-box-orient: vertical;
    word-break: break-all;
}

/* This CSS Apply on user Bio */

.post_view {
    width: 60%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
}

/* This CSS Apply on user Blog Details */

/* This CSS Apply on user Bio */

.contact_us {
    width: 200px;
    overflow: hidden;
    display: -webkit-inline-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
}

/* This CSS Apply on user Blog Details */

/* This CSS Apply on user Bio */

.admin_name_limit {
    width: 200px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
}

/* This CSS Apply on user Blog Details */

/* This CSS Apply on user Bio */

.admin_post_name_limit {
    width: 250px;
    overflow: hidden;
    display: -webkit-inline-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
}

/* This CSS Apply on user Blog Details */

.truncate_blogDetails {
    width: 100%;
    overflow: hidden;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    word-break: break-word;
}

.truncate_blogDetails font {
    font-size: 18px;
    color: #000000 !important;
    font-weight: 400;
    font-family: sans-serif;
}



.mat-form-field-infix {
    border: 0px !important;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: transparent !important;
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
    padding: 0.4375em !important;
}

.mat-form-field.mat-focused .mat-form-field-ripple {
    background: transparent !important;
    transform: scale(0) !important;
    transition: 0.2s ease-in-out !important;
    opacity: 0 !important;
}

/* Apply the Css class for shor the post length(D) */

.truncate_post {
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

.max-h-280 {
    max-height: 280 !important;
    overflow: auto;
    /* scrollbar-width: thin; */
}

/* Apply the CSS class for enhance the post length(D) */

.ln-ht1 {
    line-height: 1 !important;
}

.TagTeamGroupheight {
    overflow: auto;
    max-height: calc(100vh - 420px);
}

.postShareheight {
    overflow: auto;
    height: auto;
    max-height: 264px;
}

.max_height {
    max-height: 50px;
}

.notify-count {
    position: absolute;
    top: 5px;
    left: 45px;
}

.notify-Notification {
    position: absolute;
    top: 5px;
    left: 50px;
}

.input-opacity-dp {
    opacity: 1 !important;
}

.smallFont {
    font-size: 11px !important;
}

.img-res-popup {
    max-height: calc(95vh - 80px);
}

.carousel-control {
    opacity: 1 !important;
}

.modal-backdrop.show {
    opacity: 0.8 !important;
}

.carousel-control-prev-icon {
    background-image: url(assets/images/previous.png) !important;
    width: 30px !important;
    height: 30px !important;
}

.carousel-control-next-icon {
    background-image: url(assets/images/next.png) !important;
    width: 30px !important;
    height: 30px !important;
}

.btn-circle-custom {
    width: 28px !important;
    height: 28px !important;
    line-height: 25px !important;
    padding: 0px !important;
}

.border-rad-10 {
    border-radius: 10px;
}

.fs-9 {
    font-size: 9px;
    font-weight: 500;
}

.mw {

    max-width: 182px !important;
}



.postLikeHT {
    height: 300px;
    overflow-y: auto;
}

.bg-read {
    background-color: rgba(192, 212, 238, 0.2) !important;
    border-bottom: 1px solid #ccc !important;
}

.seperater li {
    border-bottom: 1px solid #eaeaea;
    padding: 5px 0;
}

.seperater li:last-child {
    border-bottom: none;
}

.seperater li a {
    color: #12121e;
    font-weight: 400;
}

.ck-editor__editable_inline {
    min-height: 200px;
}

.table-sm th,
.table-sm td {
    padding: 2.5rem;
    font-size: 14px;
}

/* Text Editor custom changes css(n) */

#insertImage-,
#insertVideo-,
#clearFormatting- {
    display: none !important;
}

#insertHorizontalRule- {
    margin-left: 10px !important;
}

.ae-font .ae-picker-label {
    line-height: 28px !important;
}

/* End Text Editor custom changes css(n) */

/*accordion css*/

.acc-bg {
    background: transparent;
}

ngb-accordion .card {
    background: #f8f8f8;
    margin-bottom: 5px !important;
    border: 1px solid #ebebeb !important;
    box-shadow: 0 3px 1px -2px rgba(82, 63, 104, 0.06), 0 2px 2px 0 rgba(82, 63, 104, 0.042), 0 1px 5px 0 rgba(82, 63, 104, 0.036) !important;
}

ngb-accordion .card-header {
    padding: 0px;
    border: 0px;
    border-radius: 2px !important;
    background: #fff !important;
}

ngb-accordion .card-header h5 {
    padding: 0px;
}

ngb-accordion .card-header .btn-link {
    font-weight: 400;
    color: #fff;
    text-decoration: none !important;
    width: 100%;
    text-align: left;
    background: #152b50;
    border-radius: 0px;
    padding: 15px 15px;
}

ngb-accordion .card-header .btn-link.collapsed {
    color: inherit;
    background: #152b50;
}



.dropdown-menu {
    padding: 0px !important;
}



.dropdown-privacy li a {
    font-size: 13px !important;
}

.btn-circle {
    line-height: 20px;
}

.cTab .nav-link {
    display: block;
    padding: 0.5rem 0.5rem;
}

.textNotAllowed {
    font-size: 80%;
    color: #ef5350;
}

.required-asterisk {
    color: red;
    margin-left: 2px;
    font-size: 1.2em;
}

footer {
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 9;
    background: #fff !important;
}

footer li {
    display: block;
    margin: 0 3px !important;
}

footer li a {
    display: block;
    color: initial;
    padding: 8px;
}

.bg-dark {
    background-color: #12121e !important;
}

.storyimage img {
    max-width: 100%;
}

h1.card-title,
h2.card-title {
    font-size: 20px;
}

.hContent {
    font-size: 15px;
    font-weight: 400;
    color: initial;
}

.scroll-sidebar {
    overflow: auto;
    /* scrollbar-width: thin; */
}

/* Start New loader css(D) */

#fountainG {
    position: relative;
    width: 12px;
    height: 28px;
    margin: auto;
}

.fountainG {
    position: absolute;
    top: 0;
    background-color: rgba(100, 122, 201, 0.918);
    width: 8px;
    height: 28px;
    animation-name: bounce_fountainG;
    -o-animation-name: bounce_fountainG;
    -ms-animation-name: bounce_fountainG;
    -webkit-animation-name: bounce_fountainG;
    -moz-animation-name: bounce_fountainG;
    animation-duration: 1.5s;
    -o-animation-duration: 1.5s;
    -ms-animation-duration: 1.5s;
    -webkit-animation-duration: 1.5s;
    -moz-animation-duration: 1.5s;
    animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-direction: normal;
    -o-animation-direction: normal;
    -ms-animation-direction: normal;
    -webkit-animation-direction: normal;
    -moz-animation-direction: normal;
    transform: scale(0.3);
    -o-transform: scale(0.3);
    -ms-transform: scale(0.3);
    -webkit-transform: scale(0.3);
    -moz-transform: scale(0.3);
    border-radius: 19px;
    -o-border-radius: 19px;
    -ms-border-radius: 19px;
    -webkit-border-radius: 19px;
    -moz-border-radius: 19px;
}

#fountainG_1 {
    left: 0;
    animation-delay: 0.6s;
    -o-animation-delay: 0.6s;
    -ms-animation-delay: 0.6s;
    -webkit-animation-delay: 0.6s;
    -moz-animation-delay: 0.6s;
}

#fountainG_2 {
    left: 12px;
    animation-delay: 0.75s;
    -o-animation-delay: 0.75s;
    -ms-animation-delay: 0.75s;
    -webkit-animation-delay: 0.75s;
    -moz-animation-delay: 0.75s;
}

#fountainG_3 {
    left: 24px;
    animation-delay: 0.9s;
    -o-animation-delay: 0.9s;
    -ms-animation-delay: 0.9s;
    -webkit-animation-delay: 0.9s;
    -moz-animation-delay: 0.9s;
}

#fountainG_4 {
    left: 36px;
    animation-delay: 1.05s;
    -o-animation-delay: 1.05s;
    -ms-animation-delay: 1.05s;
    -webkit-animation-delay: 1.05s;
    -moz-animation-delay: 1.05s;
}

@keyframes bounce_fountainG {
    0% {
        transform: scale(1);
        background-color: rgb(106, 136, 194);
    }

    100% {
        transform: scale(0.3);
        background-color: rgb(224, 224, 224);
    }
}

@-o-keyframes bounce_fountainG {
    0% {
        -o-transform: scale(1);
        background-color: rgb(87, 63, 63);
    }

    100% {
        -o-transform: scale(0.3);
        background-color: rgb(255, 255, 255);
    }
}

@-ms-keyframes bounce_fountainG {
    0% {
        -ms-transform: scale(1);
        background-color: rgb(0, 0, 0);
    }

    100% {
        -ms-transform: scale(0.3);
        background-color: rgb(255, 255, 255);
    }
}

@-webkit-keyframes bounce_fountainG {
    0% {
        -webkit-transform: scale(1);
        background-color: rgb(0, 0, 0);
    }

    100% {
        -webkit-transform: scale(0.3);
        background-color: rgb(255, 255, 255);
    }
}

@-moz-keyframes bounce_fountainG {
    0% {
        -moz-transform: scale(1);
        background-color: rgb(119, 106, 106);
    }

    100% {
        -moz-transform: scale(0.3);
        background-color: rgb(255, 255, 255);
    }
}

/* Start New loader css(D) */

/* Adding the image border */

.img_border {
    border: 1px solid;
    border-color: #c8cfd2;
}

.bg-light {
    background-color: #eeeeee;
}

.advertisement .carousel-item .item a {
    display: block;
    background: #eee;
    text-align: center;
}

.advertisement .carousel-item .item img {
    height: 100%;
    -o-object-fit: fill;
    object-fit: fill;
    border-radius: 2px;
    overflow: hidden;
    background-position: top;
}

@media (max-width: 768px) {
    .advertisement .carousel-item .item img {
        object-fit: contain;
    }
}

.page_wrapper_new {
    position: relative;
    padding-top: 90px;
    padding-bottom: 30px;
}

@media (max-width: 576px) {
    .advertisement .carousel-item .item img {
        object-fit: contain;
    }

    .page_wrapper_new {
        position: relative;
        padding-top: 0px;
        padding-bottom: 0px;
    }
}



@media (min-width: 992px) {
    .advertisement .carousel-item .item img {
        width: 100%;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    h4.card-title {
        font-size: 0.9rem;
    }
}

.advertisement .carousel .carousel-control {
    visibility: hidden;
}

.advertisement .carousel:hover .carousel-control {
    visibility: visible;
}

.advertisement .carousel .carousel-indicators {
    visibility: hidden;
}

.advertisement .carousel:hover .carousel-indicators {
    visibility: visible;
}

/*start Copy & paste, drop and down css(D) */

.dropzone {
    min-height: 100px;
    display: table;
    width: 100%;
    background-color: #eee;
    border: dashed 2px lightskyblue;
}

.text-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.centered {
    font-family: sans-serif;
    font-size: 1em;
    font-weight: bold;
    text-align: center;
}

/*End Copy & paste, drop and down css(D) */

.dropMediaModal {
    border: dashed 2px navy;
    border-radius: 10px;
    background-color: #26292ed8;
    opacity: 0.5;
}

.dropMediaMain {
    opacity: 0.9;
    background-color: rgba(225, 225, 225, 0.806);
    border: 2px dashed;
}

.noDropMedia {
    opacity: 1;
}

.center {
    font-family: sans-serif;
    font-size: 1em;
    font-weight: bold;
    text-align: center;
    z-index: 1000;
}



.slide_photobox .item {
    display: flex;
    justify-content: center;
}

.photobox-comment {
    width: 350px;
    min-height: 100%;
    background: white;
    z-index: 99;
}

.photobox_image_width {
    width: 50vw;
    max-width: 100%;
    height: 70vh !important;
    object-fit: contain;
    background: #eaeaea;
    margin: auto;
}

.photobox-icon {
    margin: 15px auto !important;
    position: unset;
    width: 13rem;
    bottom: 4.5rem;
    right: 0;
    padding: 0.5rem;
    background: #fff;
    border-radius: 50px;
    background-color: #152b50;
    z-index: 99;
}

.doCommentMedia {
    padding: 0.5rem;
}

.seeCommentMedia {
    max-height: 63vh !important;
    overflow: auto !important;
    padding: 0.5rem;
}

.carousel-arrows .carousel-control-next.right_m {
    right: 22rem;
}

/* for show more and show less(D) */

.showMore {
    width: 100%;
    height: 100%;
}

.showLess {
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: break-all;
}

.fs-12 {
    font-size: 12px !important;
}

@media (max-width: 1024px) {
    .slide_photobox .item {
        flex-direction: column;
    }

    .photobox_image_width {
        width: 100%;
        height: 50vh !important;
    }

    .photobox-comment {
        width: 100%;
        position: relative;
    }

    .seeCommentMedia {
        min-height: 100%;
        max-height: 50vh !important;
    }

    .carousel-arrows .carousel-control-prev.left_m {
        top: 0 !important;
        bottom: 50% !important;
    }

    .carousel-arrows .carousel-control-next.right_m {
        right: 0;
        top: 0 !important;
        bottom: 50% !important;
    }
}

@media screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) {
    .mailbox .message-center {
        max-height: 250px;
    }

    .sticky {
        position: static !important;
    }

    .TagTeamGroupheight {
        max-height: 100% !important;
    }

    .topbar ul.dropdown-user {
        height: calc(100vh - 90px);
        overflow: auto;
    }
}

@media (min-width: 2400px) {
    .carousel-arrows .carousel-control-next.right_m {
        right: 15rem;
    }
}

@media (min-width: 2200px) and (max-width: 2399px) {
    .carousel-arrows .carousel-control-next.right_m {
        right: 16rem;
    }
}

@media (min-width: 2000px) and (max-width: 2199px) {
    .carousel-arrows .carousel-control-next.right_m {
        right: 17rem;
    }
}

@media (min-width: 1800px) and (max-width: 1999px) {
    .carousel-arrows .carousel-control-next.right_m {
        right: 18.5rem;
    }
}

@media (min-width: 1600px) and (max-width: 1799px) {
    .carousel-arrows .carousel-control-next.right_m {
        right: 20.5rem;
    }
}

@media (min-width: 1200px) and (max-width: 1299px) {
    .carousel-arrows .carousel-control-next.right_m {
        right: 21rem;
    }

    .carousel-arrows .carousel-control-prev.left_m {
        left: -3rem;
    }
}

@media (min-width: 1025px) and (max-width: 1199px) {


    .carousel-control-prev {
        left: 130px !important;
    }

    .carousel-control-next {
        right: 130px !important;
    }
}


.truncate_bio.text-break .truncate_post_url {
    display: none !important;
}

.mediaComment {
    overflow-wrap: break-word;
}

.md-drppicker {
    overflow: scroll !important;
    max-height: 388px;
    padding: 0 !important;
}

.md-drppicker.drops-down-right {
    left: auto !important;
    right: 0 !important;
}

.table-height {
    height: calc(100vh - 306px);
    overflow: auto;
}

.left-chat-text a {
    color: #fff;
}

.left-chat-text a:hover {
    color: #fff !important;
}

.phoneTabColor .followTabs .f-active a {
    background: #990099 !important;
    color: white;
}

.comment_scroll {
    overflow: auto;
    max-height: 100px !important;
    /* border-radius: 10px !important; */
    border: none;
}

.lightbox-modal .modal-content {
    background: transparent;
    border: 0px;
}

@media (max-width: 1024px) {
    .comment_scroll {
        max-height: 80px !important;
    }

    .lightbox-modal .modal-content {
        background: transparent;
        border: 0px;
    }
}

.stickyTab {
    position: sticky;
    z-index: 9;
    top: 63px;
}

@media (max-width: 990px) {
    .stickyTab {
        position: sticky;
        z-index: 9;
        top: 50px;
    }
}

@media (max-width: 767px) {
    .stickyTab {
        position: sticky;
        z-index: 9;
        top: 0px !important;
    }
}

textarea::placeholder {
    color: #67757c !important;
}

.btn-circle.btn-sm {
    padding: 6px 0px;
    border-radius: 15px;
    text-align: center;
}

.btn-circle-title1.btn-sm {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    font-size: inherit;
    font-weight: 600;
    text-align: center;
    float: right;
    padding: inherit;
    margin: 10px;
}

.postImg .btn-circle-title1.btn-sm {
    position: absolute;
    right: 15px;
}

.btn-circle-title.btn-sm {
    width: 22px;
    height: 22px;
    border-radius: 15px;
    text-align: center;
    font-size: inherit;
    margin-bottom: auto;
    float: right;
    padding: initial;
}

.m-l-56 {
    margin-left: 56px;
}

.placeholderOneOrThree {
    height: 100%;
    min-height: 400px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #dddfdd;
    opacity: 0.5;
}

.placeholderModal {
    height: 400px;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #d0d4d0;
}

.placeholderTwoOrFour {
    height: 100%;
    min-height: 200px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #dddfdd;
    opacity: 0.5;
}

.placeholderFiveOrMore {
    height: 100%;
    min-height: 200px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #dddfdd;
    opacity: 0.5;
}

.placeholderMediaGallary {
    height: 100%;
    width: 100%;
    min-height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #dfdfdf;
}

.placeholderMessaageChat {
    max-height: 250px;
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #dfdfdf;
}

#loaderProgressive {
    border: 2px solid #2d6fc6;
    border-radius: 50%;
    border-top: 2px solid #dddfdd;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

.centerProgressive {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.progressive-image {
    display: contents !important;
}

.centerProgressiveGallary {
    color: #448bf7;
    font-size: 25px;
    text-indent: -9999em;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin: 72px auto;
    position: relative;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
    animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}

.centerProgressiveGallaryInside {
    color: #448bf7;
    font-size: 40px;
    text-indent: -9999em;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin: 72px auto;
    position: relative;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
    animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}

@-webkit-keyframes load6 {
    0% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }

    5%,
    95% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }

    10%,
    59% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }

    20% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }

    38% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }

    100% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
}

@keyframes load6 {
    0% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }

    5%,
    95% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }

    10%,
    59% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }

    20% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }

    38% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }

    100% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
}

@-webkit-keyframes round {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes round {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.custom-dropdown.dropdown-menu {
    width: 100%;
    min-width: 300px;
    overflow: hidden;
}

.dropdown-privacy li a small {
    white-space: normal !important;
}

.custom-dropdown .dropdown-item {
    white-space: normal;
    display: block;
}

.privacy-heading {
    font-weight: 400;
}


.fb-profile-text h3 {
    color: #152B50;
    font-weight: 500;
}

a.label-info {
    color: white !important;
}

.table>tbody>tr>td>h6 {
    color: initial;
    font-weight: 500;
}


.selected-list .c-list .c-token .c-label {
    color: #2d368e;
}

.selected-list .c-list .c-token .c-remove svg {
    fill: #2d368e !important;
}

.pure-checkbox input[type="checkbox"]:focus+label:before,
.pure-checkbox input[type="checkbox"]:hover+label:before {
    border-color: #2d6fc6;
    background-color: #f2f2f2;
}

.pure-checkbox input[type="checkbox"]+label {
    color: #000;
}

.pure-checkbox input[type="checkbox"]+label:before {
    color: #2d6fc6;
    border: 1px solid #2d6fc6;
}

.pure-checkbox input[type="checkbox"]+label:after {
    background-color: #2d6fc6;
}

.pure-checkbox input[type="checkbox"]:checked+label:before {
    background: #2d6fc6;
}

.single-select-mode .pure-checkbox input[type="checkbox"]:focus+label:before,
.single-select-mode .pure-checkbox input[type="checkbox"]:hover+label:before {
    border-color: #2d6fc6;
    background-color: #f2f2f2;
}

.single-select-mode .pure-checkbox input[type="checkbox"]+label:before {
    color: transparent !important;
    border: 0px solid #2d6fc6;
}

.single-select-mode .pure-checkbox input[type="checkbox"]+label:after {
    border-color: #2d6fc6;
}

.btn-iceblue {
    background: #2d6fc6;
    border: 1px solid #ccc;
    color: #fff;
}

.grid-btn-wrapper {
    display: flex;
    grid-gap: 5px;
    flex-wrap: wrap;
}


.newsImgLoader {
    height: 200px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #dddfdd;
    opacity: 0.5;
    position: relative;
}

.lazyContainer {
    position: relative !important;
}

.text-truncate {
    white-space: nowrap;
    min-width: 35px;
    overflow: hidden;
    text-overflow: ellipsis;

}

.custom-index {
    z-index: 1 !important;
}

.myclass .dropdown-list {
    position: absolute;
    padding-top: 14px;
    width: 100%;
    z-index: 2;
}

.img-mh {
    max-height: 40px !important;
}

.img-suggest {
    max-height: 80px !important;
}

.thumbnail {
    max-height: 199px !important;
}

@media (max-width: 767px) {
    .thumbnail {
        max-height: 150px !important;
    }
}

@media (max-width: 576px) {
    .thumbnail {
        max-height: 130px !important;
    }
}

.nfl-badge {
    color: #ffffff !important;
    background-color: #d70302;
}

.nfl-badge-team {
    color: #d70302 !important;
    background-color: #ffffff;
    border: 1px solid #d70302;
}

.mlb-badge {
    color: #ffffff !important;
    background-color: #f8c108;
}

.mlb-badge-team {
    color: #f8c108 !important;
    background-color: #ffffff;
    border: 1px solid #f8c108;
}

.nhl-badge {
    color: #ffffff !important;
    background-color: #000000;
}

.nhl-badge-team {
    color: #000000 !important;
    background-color: #ffffff;
    border: 1px solid #000000;
}

.nba-badge {
    color: #ffffff !important;
    background-color: #fd7b01;
}

.nba-badge-team {
    color: #fd7b01 !important;
    background-color: #ffffff;
    border: 1px solid #fd7b01;
}

.ncaa-football-badge {
    color: #ffffff !important;
    background-color: #265eb1;
}

.ncaa-football-badge-team {
    color: #265eb1 !important;
    background-color: #ffffff;
    border: 1px solid #265eb1;
}

.ncaa-basketball-badge {
    color: #ffffff !important;
    background-color: #265eb1;
}

.ncaa-basketball-team {
    color: #265eb1 !important;
    background-color: #ffffff;
    border: 1px solid #265eb1;
}

.mls-badge {
    color: #ffffff !important;
    background-color: #02ad58;
}

.mls-badge-team {
    color: #02ad58 !important;
    background-color: #ffffff;
    border: 1px solid #02ad58;
}

.tennis-badge {
    color: #ffffff !important;
    background-color: #7e38f1;
}

.tennis-badge-team {
    color: #7e38f1 !important;
    background-color: #ffffff;
    border: 1px solid #7e38f1;
}

.golf-badge {
    color: #ffffff !important;
    background-color: #13386a;
}

.golf-badge-team {
    color: #13386a !important;
    background-color: #ffffff;
    border: 1px solid #13386a;
}

.ipl-badge {
    color: #ffffff !important;
    background-color: #008bd2;
}

.ipl-badge-team {
    color: #008bd2 !important;
    background-color: #ffffff;
    border: 1px solid #008bd2;
}

.icc-t20-men-badge {
    color: #ffffff !important;
    background-color: #e72687;
}

.icc-t20-men-badge-team {
    color: #e72687 !important;
    background-color: #ffffff;
    border: 1px solid #e72687;
}

.default-badge {
    color: #000000 !important;
    background-color: #c0d4ee;
}

.default-badge-team {
    color: #c0d4ee !important;
    background-color: #000000;
    border: 1px solid #c0d4ee;
}

.coach-badge {
    position: absolute;
    float: left;
    top: 6px;
    left: 5px;
    color: #152b50;
    padding: 0px 7px;
    font-size: 10px;
    font-weight: 300;
    border-radius: 0.5rem;
    text-transform: uppercase;
}

.coach-badge-list {
    position: absolute;
    float: left;
    font-size: 8px;
    top: auto;
    margin: 2px;
    left: 28px;
    color: #152b50;
    padding: 1px 5px 0px;
    border-radius: 0.5rem;
}

.form-control-design1 {
    border-radius: 8px !important;
    background-color: transparent !important;
}

.border-radius-10 {
    border-radius: 10px !important;
}

.btn-info-new {
    background: #3e70c0;
    border: 1px solid #3e70c0;
    -webkit-transition: .2s ease-in;
    transition: .2s ease-in;
    color: #fff;
}

.btn-light-new {
    background: #c4d3ec;
    border: 1px solid #c4d3ec;
    -webkit-transition: .2s ease-in;
    transition: .2s ease-in;
    color: #fff;
}

.pos-rel-2 {
    position: relative;
    top: 2px;
}

.pos-rel-1 {
    position: relative;
    top: 1px;
}

.pos-minus-4 {
    position: relative;
    top: -4px;
}

.bg-gray {
    background-color: #5f738b !important;
    color: #fff !important;
}

.color-gray {
    color: #5f738b !important;
}

a.text-info:hover {
    text-decoration: underline;
}

.post-text-margin {
    margin-top: 15px;
    margin-bottom: 15px;
}

.dropdown-menu-new {
    position: absolute !important;
    top: 100% !important;
    left: 0px !important;
}

a.bg-light {
    background-color: #e1e8f1 !important;
}

.modal-header-new {
    padding: 20px 1rem !important;
}

.modal-body-new {
    padding: 5px 1rem 10px !important;
}

.close_img-new {
    right: 0px;
    top: -7px;
    z-index: 9;
    background: #fff;
    width: 18px;
    height: 18px;
    border-radius: 50px;
    text-align: center;
    box-shadow: 0px 1px 2px #000;
}

.close_img-new span {
    font-size: 12px;
    position: relative;
    top: -2px;
}

a.bg-light-new {
    background-color: #e1e8f1 !important;
}



.dropdown-item {
    padding: 5px 0.5rem !important;
    text-align: justify !important;
}

.media p.text-dark {
    font-size: 15px;
    font-weight: 500;
    margin-top: 15px;
}

.fade.in {
    opacity: 1 !important;
}

.modal.in .modal-dialog {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
}

.modal-backdrop.in {
    opacity: 0.8 !important;
    z-index: 99998;
}

.modal-open .modal {
    z-index: 99999;
}


.mdi-magnify:before {
    color: #152B50 !important;
}


.bg-dark-blue {
    background-color: #152b50 !important;
    color: #fff !important;
}

.h-100 {
    height: 100% !important;
}

.selected-list .c-list .c-token {
    background: #c0d4ee;
    margin-right: 5px !important;

}

img.rounded-circle {
    border-radius: 0.25rem !important;
    aspect-ratio: 1;
}

.followTabs li {
    width: 25% !important;
}

.break-all {
    word-break: break-word !important;
}

.mw-name {
    max-width: 65% !important;
    min-width: 20% !important;
}


::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    /* scrollbar-width: thin; */
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    scrollbar-track-offset: -20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 0.5rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* default scroll bar ends */


.my-new-scroll {
    overflow-y: scroll;
}

/* default scroll bar ends */

.igt-modal {
    background-color: #dcdcdc;
    padding: 0.5rem !important;
}

.fs-15 {
    font-size: 15px !important;
}

.fs-13 {
    font-size: 13px !important;
}

.seperator-line.centered {
    margin-right: auto;
    margin-left: auto;
    background-color: #bcbcbc;
}

.seperator-line {
    width: 10%;
    height: 3px;
    background-color: #152b50;
}


#delete-icon:hover {
    content: url('assets/icons/Trash_Icon_Hover.svg');
}

#edit-icon:hover {
    content: url('assets/icons/Edit_Image_Pencil_Hover_State.svg');
}

.hide_element {
    visibility: hidden !important;
}

.share_hover:hover img {
    content: url('assets/icons/Hover_Share_Icon.svg');
}

.like_hover:hover img {
    content: url('assets/icons/Hover_High_Five.svg');
}

.liked_hover:hover img {
    content: url('assets/icons/Hover_High_Fived.svg');
}

.comment_hover:hover img {
    content: url('assets/icons/Hover_Comment_Icon.svg');
}

.invite_hover:hover img {
    content: url('assets/icons/Hover_Invite_Friend.svg');
}

.profile-icons .myicon:hover {
    background: #e5e9f0;
}

.dark_bg:hover {
    background: #0b1e3d !important;
}

.edit_hover {
    background: transparent;
    border-radius: 0.25rem;
    padding: 0 0.25rem;
    margin: 2px 0;
}

.edit_hover:hover {
    background: #e5e9f0;
    border-radius: 0.25rem;
    padding: 0 0.25rem;
    margin: 2px 0;
}

.mw-110 {
    max-width: 110px !important;
    display: inline-block !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
}

.pl-new-0 {
    padding-left: 0px !important;
}

.name-line-height {
    line-height: 18px !important;
}

.list-menu-height {
    height: auto !important;
}

@media screen and (max-width: 767px) {
    .pl-new-0 {
        padding-left: 15px !important;
    }

    .name-line-height {
        line-height: 12px !important;
    }

    .list-menu-height {
        height: 10px !important;
    }
}





/* Content-Loading css */

.comment-loader {
    height: 20px;
    margin-left: 13px;
}

.sg-left-loader {
    height: 120px !important;
    border-radius: 10px;
}

.sg-right-top-loader {
    height: 55px !important;
    border-radius: 10px;
}

.sg-right-bottom-loader {
    height: 55px !important;
    border-radius: 10px;
}

.sub-rect-event {
    width: 50px !important;
}

.sub-rect {
    border-radius: 100%;
    width: 50px;
    height: 50px;
    float: left;
}

.sub-rect-news {
    border-radius: 0.25rem;
    width: 30px;
    height: 2vh;
    float: left;
}

.sub-rect-user-follow {
    border-radius: 0.25rem;
    width: 70px;
    height: 25px;
    float: left;
}

.sub-rect-connection {
    border-radius: 100%;
    width: 30px;
    height: 30px;
    float: left;
}

.sub-rect-user-list {
    border-radius: 10px;
    width: 50px;
    height: 50px;
    float: left;
}

.pure-background {
    background-color: #e6e6e6;
}

.first-loader-name {
    height: 2vh;
    max-width: 150px;
    margin-left: 13px;
}

.first-loader-name-news {
    height: 2vh;
    max-width: 100%;
    margin-left: 13px;
}

.first-loader-content {
    height: 50vh;
}

.first-loader-ad {
    height: 100%;
}

.first-loader-profile {
    height: 100%;
}

.first-loader-bottom-line {
    height: 2vh;
    max-width: 100px;
}

.btn-divide-left {
    top: 0;
    left: 25%;
    height: 100%;
}

.main-item {
    padding: 10px;
    background-color: #fff;
    width: 700px;
}

.sub-rect-sports-exp {
    border-radius: 25% !important;
}





@keyframes placeHolderShimmer {

    0% {
        background-position: -800px 0
    }

    100% {
        background-position: 800px 0
    }


}

@-webkit-keyframes placeHolderShimmer {

    0% {
        background-position: -800px 0
    }

    100% {
        background-position: 800px 0
    }


}

.animated-background {
    animation-duration: 4s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background-color: #f6f7f8;
    background: linear-gradient(to right, #cdc4c41c 10%, #d2d2d24f 50%, #e5e5e563 80%);
    background-size: 800px 104px;
    position: relative;


}

.animated-background-name {
    animation-duration: 4s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background-color: #f6f7f8;
    background: linear-gradient(to right, #c1c0c0 10%, #878484e3 50%, #e6e6e6 80%);
    background-size: 800px 104px;
    position: relative;
    border-radius: 8px;

}

.static-background {
    background-color: #f6f7f8;
    background-size: 800px 104px;
    height: 70px;
    position: relative;
    margin-bottom: 20px;
}





/* End Content-Loading css */

.invite-button {
    width: 70px !important;
}

@media screen and (min-width: 1025px) {
    .modal-dialog.modal-xxl {
        width: 100% !important;
        height: 100%;
        max-width: 100% !important;
        margin: 0px !important;
    }

    .photobox_image_width {

        width: 95% !important;
        height: 100vh !important;
    }

    .photo-box-custome-size {
        width: 70% !important;
        margin-top: 2%;
    }

    .comment-box-custome-size {
        width: 30% !important;
    }

    .comment-box-custome-size h2 {
        padding-left: 10px;
    }

    .img-res-popup {
        max-height: calc(95vh - 80px);
    }

    .photobox-comment {
        width: 350px;
        min-height: 100%;
        background: #f9f9f9;
        z-index: 99;
        border-left: 1px solid #ddd;
        padding-left: 5px;
    }

    .comment-box-heading {
        margin-top: 15px;
        border-bottom: 1px solid #ddd;
        margin-bottom: 15px;
        padding-bottom: 10px;
    }

    .igt-modal {
        background-color: #f1f1f1;
    }

    .media-modal-custome-close {
        opacity: 1;
        position: absolute;
        border: none;
        left: 30px;
        top: -17px;
        background: none;
        z-index: 1000;
    }

    .media-modal-custome-close span {
        padding: 5px;
        width: 35px;
        height: 35px;
        border-radius: 20px;
        background: #0000005c;
        position: absolute;
    }

    button.media-modal-custome-close:focus {
        outline: none;
    }

    .modal-content.modal-content-custom {
        height: 100% !important;
    }

}


@media screen and (max-width: 992px) {
    .carousel-arrows .carousel-control-prev.left_m {
        top: 7rem !important;
    }

    .carousel-arrows .carousel-control-next.right_m {
        right: 0;
        top: 7rem !important;

    }
}

@media screen and (min-width: 992px) and (max-width: 1024px) {
    .carousel-arrows .carousel-control-prev.left_m {
        top: 8rem !important;
    }

    .carousel-arrows .carousel-control-next.right_m {
        right: 0;
        top: 8rem !important;

    }
}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
    .carousel-arrows .carousel-control-prev.left_m {
        top: 12rem !important;
        left: -3rem !important;
    }

    .carousel-arrows .carousel-control-next.right_m {
        top: 12rem !important;
        right: calc(100vw - 73vw) !important;

    }
}

@media screen and (min-width: 1440px) {
    .carousel-arrows .carousel-control-prev.left_m {
        top: 21rem !important;
        left: -4rem !important;
    }

    .carousel-arrows .carousel-control-next.right_m {
        top: 21rem !important;
        right: calc(100vw - 75vw) !important;

    }
}

.bg-change .modal-content {
    background-color: #0d0d0fba !important;
}

.bg-change .igt-modal {
    background-color: transparent !important;
}

.bg-change .photobox_image_width {
    background-color: transparent !important;
}

.dropdown-dot-pos {
    font-size: 20px !important;
    font-weight: 700 !important;
    position: relative !important;
    bottom: 4px !important;
    color: #000;
}

.fs-14 {
    font-size: 14px !important;
}

.hide-sort::after {
    content: none !important;
}

.btn-light:hover {
    background: #152b50 !important;
    border: 1px solid #152b50;
    color: #ffffff !important;
    font-weight: 500;
}

.content-area {
    border: 1px solid;
    /* padding: 5px 5px 0px 5px; */
    padding: 5px 5px 5px 5px;
    border-radius: 10px;
    width: 100%;
}

.privacy-desc-line-height {
    line-height: 1.5em;
}

.image-ratio {
    aspect-ratio: 1/1 !important;
}

.gif-button {
    border: none !important;
    padding: 4px !important
}

.dropdown-emoji-modal {
    z-index: 99;
    transform: translateY(265px);
    -webkit-transform: translateY(265px);
}

.select-tag-box {
    margin-right: 17px !important;
}

@media screen and (max-width:768px) {
    .dropdown-emoji-modal {
        z-index: 99;
        transform: translateY(280px);
        -webkit-transform: translateY(280px);
    }
}

@media screen and (max-width:375px) {
    .text-reply small {
        font-size: 65%;
    }
}

.custom-class .selected-list .c-list .c-token .c-label {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

@media screen and (max-width: 768px) {
    .truncate_blogDetails p {
        font-size: 15px;
    }

    .truncate_blogDetails p font {
        font-size: 15px;
    }


    .truncate_blogDetails>span>p>font {
        font-size: 15px;
    }
}

.invalid-feedback-1 {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #ef5350;
}

.gender_class {
    background-color: #152B50;
    color: #fff;
}

.division_class {
    background-color: #152B50;
    color: #fff;
}

.dropup {
    transform: translateY(-172px) !important;
}

.dropup-cus {
    transform: translateY(-115%) !important;
}

.cdk-overlay-pane {
    top: auto;
}

.cdk-overlay-container {
    position: fixed;
    z-index: 1090;
}

.media-emoji-transform {
    transform: translateY(300px);
}

.media-gif-sticker-transform {
    transform: translateY(115%);
}

.search-media {
    position: relative;
    top: -35px;
    left: 90%;
}

.league_date .md-drppicker.drops-down-right {
    height: 300px !important;
}

.league_date .md-drppicker .buttons {
    position: absolute;
    top: auto;
    bottom: 0px;
    right: 0px;
}

.league_date .md-drppicker {
    height: 290px !important;
    width: 500px !important;
    top: 45px !important
}

.match_cal .md-drppicker {
    top: auto !important;
    left: auto !important;
    right: 5px !important;
}

.width-small-8{
    width:8%;
   }
   .width-small-92{
       width:92%;
      }
   