// Variables
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700");
$bodyfont: sans-serif;
$headingfont: sans-serif;

/*Theme Colors*/

$topbar: #1976d2;
$sidebar: #fff;
$sidebar-white: #e5edef;
$sidebar-alt: #edf0f5;
$bodycolor: #5286c5; //#f4f6f9;
$headingtext: #dd1240;
$bodytext: #67757c;
$sidebar-text: #687384;
$sidebar-icons: #555f6d;
$light-text: #a6b7bf;
$themecolor: #c0d4ee;

$textthemecolor: #152b50;
$themecolor-alt: #26c6da;
$themecolor-dark: #028ee1;

/*bootstrap Color*/

$danger: #ef5350;
$success: #2d6fc6;
$warning: #ffb22b;
$primary: #745af2;
$info: #c4d4ec;
$info-light: #152b50;
$inverse: #2f3d4a;
$secondary: #cccccc;
$muted: #99abb4;
$dark: #263238;
$light: #f4f6f9;
$extra-light: #ebf3f5;
$bglight: rgba(0,
0,
0,
0.02);

/*Light colors*/

$light-danger: #f9e7eb;
$light-success: #e8fdeb;
$light-warning: #fff8ec;
$light-primary: #f1effd;
$light-info: #cfecfe;
$light-inverse: #f6f6f6;
$light-megna: #e0f2f4;
$danger-dark: #e6294b;
$success-dark: #04b381;
$warning-dark: #e9ab2e;
$primary-dark: #6352ce;
$info-dark: #028ee1;
$red-dark: #d61f1f;
$inverse-dark: #232a37;
$dark-transparent: rgba(0,
0,
0,
0.05);
$info-shadow: 0 2px 2px 0 rgba(66,
165,
245,
0.14),
0 3px 1px -2px rgba(66,
165,
245,
0.2),
0 1px 5px 0 rgba(66,
165,
245,
0.12);
$info-shadow-hover: 0 14px 26px -12px rgba(23,
105,
255,
0.42),
0 4px 23px 0 rgba(0,
0,
0,
0.12),
0 8px 10px -5px rgba(23,
105,
255,
0.2);
$warning-shadow: 0 2px 2px 0 rgba(248,
194,
0,
0.14),
0 3px 1px -2px rgba(248,
194,
0,
0.2),
0 1px 5px 0 rgba(248,
194,
0,
0.12);
$warning-shadow-hover: 0 14px 26px -12px rgba(248,
194,
0,
0.42),
0 4px 23px 0 rgba(0,
0,
0,
0.12),
0 8px 10px -5px rgba(248,
194,
0,
0.2);
$danger-shadow: 0 2px 2px 0 rgba(239,
83,
80,
0.14),
0 3px 1px -2px rgba(239,
83,
80,
0.2),
0 1px 5px 0 rgba(239,
83,
80,
0.12);
$danger-shadow-hover: 0 14px 26px -12px rgba(239,
83,
80,
0.42),
0 4px 23px 0 rgba(0,
0,
0,
0.12),
0 8px 10px -5px rgba(239,
83,
80,
0.2);
$success-shadow: 0 2px 2px 0 rgba(40,
190,
189,
0.14),
0 3px 1px -2px rgba(40,
190,
189,
0.2),
0 1px 5px 0 rgba(40,
190,
189,
0.12);
$success-shadow-hover: 0 14px 26px -12px rgba(40,
190,
189,
0.42),
0 4px 23px 0 rgba(0,
0,
0,
0.12),
0 8px 10px -5px rgba(40,
190,
189,
0.2);
$primary-shadow: 0 2px 2px 0 rgba(116,
96,
238,
0.14),
0 3px 1px -2px rgba(116,
96,
238,
0.2),
0 1px 5px 0 rgba(116,
96,
238,
0.12);
$primary-shadow-hover: 0 14px 26px -12px rgba(116,
96,
238,
0.42),
0 4px 23px 0 rgba(0,
0,
0,
0.12),
0 8px 10px -5px rgba(116,
96,
238,
0.2);
$default-shadow: 0 2px 2px 0 rgba(169,
169,
169,
0.14),
0 3px 1px -2px rgba(169,
169,
169,
0.2),
0 1px 5px 0 rgba(169,
169,
169,
0.12);
$default-shadow-hover: 0 14px 26px -12px rgba(169,
169,
169,
0.42),
0 4px 23px 0 rgba(0,
0,
0,
0.12),
0 8px 10px -5px rgba(169,
169,
169,
0.2);

/*Normal Color*/

$white: #ffffff;
$red: #fb3a3a;
$yellow: #a0aec4;
$purple: #7460ee;
$blue: #02bec9;
$megna: #56c0d8;

/*Extra Variable*/

$rgt: right;
$lft: left;
$border: rgba(120,
130,
140,
0.13);
$table-border: #f3f1f1;
$card-brd: #d7dfe3;
$dark-text: #848a96;
$radius: 4px;
$form-brd: #b1b8bb;

/*Preloader*/

.preloader {
    width: 100%;
    height: 100%;
    top: 0px;
    position: fixed;
    z-index: 99999;
    background: #fff;
    .cssload-speeding-wheel {
        position: absolute;
        top: calc(50% - 3.5px);
        left: calc(50% - 3.5px);
    }
}